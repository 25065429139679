import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./roots/app.component";
import { Provider } from "react-redux";
import { store } from "./store";
import axios from "axios";

axios.defaults.baseURL = "http://103.84.159.19";

ReactDOM.render(
  <Provider store={store}>
    <Fragment>
      <App />
    </Fragment>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
