import React from "react";
import HomeSlider from "../../components/home/Slider/slider.component";
import EventCardSlider from "../../components/home/Events/eventCardSlider.component";
import AchievementCardSlider from "./../../components/home/Achievements/achievementCardSlider.component";

function Home() {
  return (
    <div className="Home">
      <HomeSlider />

      <div className="level">
        <div className="level-item has-text-centered">
          <div>
            <h1>
              Events
              <div
                className="divider"
                style={{ border: "2px solid #A51C30" }}
              ></div>
            </h1>
          </div>
        </div>
      </div>
      <section className="section">
        <EventCardSlider />
      </section>

      <section className="section" style={{ background: "#A51C30" }}>
        <div className="level">
          <div className="level-item has-text-centered">
            <div>
              <h1 style={{ color: "#FFFFFF" }}>
                Achievements
                <div
                  className="divider"
                  style={{ border: "2px solid #FFFFFF" }}
                ></div>
              </h1>
            </div>
          </div>
        </div>
        <AchievementCardSlider />
      </section>
    </div>
  );
}

export default Home;
