import React from "react";
import { Link, withRouter } from "react-router-dom";
import "./faculty_card.styles.scss";
import "../../../../assets/icons/academicons-1.8.6/css/academicons.min.css";

const FacultyCard = ({ id, name, designation, avatar }) => (
  <div className="card-container">
    <div
      className="con"
      style={{
        background: `url(${avatar}) no-repeat`,
        backgroundSize: "cover",
        backgroundPosition: "center center"
      }}
    >
      <div className="overlay">
        <div className="items">
          <div className="items head">
            <p>{name}</p>
            <hr />
          </div>
          <div className="items details">
            <p>{designation}</p>
          </div>
          <div className="items link">
            <Link to={`/people/faculty/${id}/details`}>Know more...</Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default withRouter(FacultyCard);
