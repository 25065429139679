import React, { Fragment } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import Loading from "../../../components/loading/loading.component";
import DateTime from "../../../images/calendar.svg";
import Location from "../../../images/placeholder.svg";
import TimeParser from "../../../utils/time-parser";
import { getAchievementDetails } from "./../../../actions/achievements";

class AchievementDetail extends React.Component {
  state = {
    loading: true
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getAchievementDetails(id);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({
        loading: nextProps.achievements.loading
      });
    }
  }

  render() {
    const { loading } = this.state;
    const {
      title,
      location,
      content,
      images,
      created_at
    } = this.props.achievements.achievement_details;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      centerMode: true
    };
    return (
      <Fragment>
        {loading ? (
          <div className="loading">
            <Loading loading={loading} type={"ball"} />
          </div>
        ) : (
          <Fragment>
            <div className="container" style={{ minHeight: "100vh" }}>
              <h1>
                {title}
                <br />
                <img
                  src={DateTime}
                  width="16px"
                  height="16px"
                  alt="date-time"
                />
                <span className="text">{TimeParser(created_at)}</span>

                <img src={Location} width="16px" height="16px" alt="location" />
                <span className="text">{location}</span>
                <div
                  className="divider"
                  style={{ border: "3px solid #A51C30" }}
                ></div>
              </h1>
              <br />
              <div className="slide-container-left">
                <Slider {...settings}>
                  {images.map((value, index) => (
                    <img src={value} key={index} alt={value} />
                  ))}
                </Slider>
              </div>
              <div className="text-container-right">
                <p>{content}</p>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  achievements: state.achievements
});

export default connect(
  mapStateToProps,
  { getAchievementDetails }
)(AchievementDetail);
