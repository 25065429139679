import React from "react";
import { withRouter } from "react-router-dom";
import "./Scss/eventsCard.styles.scss";
import TimeParser from "./../../../utils/time-parser";

const Events = ({ id, images, created_at, title, history }) => {
  return (
    <div className="card eq-hight">
      <div className="card-image">
        <figure className="image is-4by3">
          <img src={images[0]} alt="card_header" />
        </figure>
        <div
          className="card-content"
          style={{ background: "#1E2126", borderRadius: `${0}` }}
        >
          <div className="level">
            <div className="level-item has-text-centered">
              <div>
                <h3 style={{ color: "#F6F0F0" }}>
                  {TimeParser(created_at)}
                  <div
                    className="divider"
                    style={{ border: "1px solid #F6F0F0" }}
                  ></div>
                </h3>
                <br />
                <h4 style={{ color: "#F6F0F0" }}>{title}</h4>
                <br />
                <button
                  className="e-btn button center-button"
                  onClick={() =>
                    history.push(`/activities/events/${id}/details`)
                  }
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Events);
