import axios from "axios";
import { GET_EVENTS, GET_EVENT_DETAIL, SPINNER_LOADING } from "./types";

export const getEvents = () => dispatch => {
  dispatch({
    type: SPINNER_LOADING
  });
  axios
    .get("/api/v1/events_achievement/events")
    .then(res =>
      dispatch({
        type: GET_EVENTS,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};
export const getEventDetails = id => dispatch => {
  dispatch({
    type: SPINNER_LOADING
  });
  axios
    .get(`/api/v1/events_achievement/events/${id}`)
    .then(res =>
      dispatch({
        type: GET_EVENT_DETAIL,
        payload: res.data
      })
    )
    .catch(err => console.log(err));
};
