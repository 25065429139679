import React from "react";
import Login from "../accounts/login/login.component";

const Helps = () => {
  return (
    <div className="helps">
      <h1 className="title">
        Please enter your login info for most promissing information.
      </h1>
      <Login />
    </div>
  );
};
export default Helps;
