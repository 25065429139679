import React from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "normalize.css/normalize.css";
import "./slider-animations.css";
import "./slider.styles.css";

const content = [
  {
    title: "SWE Annual Tour In St. Martin's Island",
    image: "https://i.ibb.co/Q80yVJ4/event-swe-tour-2k19.jpg"
  },
  {
    title: "SWE Cultural Night 2k19",
    image: "https://i.ibb.co/mT33cPj/event-cultural-night.jpg"
  },
  {
    title: "Seminar On How To Kick Start Your Software Engineering Career.",
    image: "https://i.ibb.co/z7H3w6D/Achievement-SWE-career-starting.jpg"
  }
];

const HomeSlider = () => (
  <div>
    <Slider className="slider-wrapper" autoplay={1000} touchDisabled={true} previousButton={"arrow svg"}>
      {content.map((item, index) => (
        <div
          key={index}
          className="slider-content"
          style={{
            background: `url("${item.image}") no-repeat center center`
          }}
        >
          <div className="inner">
            <h1>{item.title}</h1>
          </div>
        </div>
      ))}
    </Slider>
  </div>
);

export default HomeSlider;
