import React from "react";

class Contact extends React.Component {
  render() {
    return (
      <div>
        <h1>This is Contact Page</h1>
      </div>
    );
  }
}

export { Contact };
