import React from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import Achievement from "./achievementCard.component";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Scss/achievementCardSlider.styles.scss";
import { getAchievements } from "./../../../actions/achievements";
import Loading from "../../loading/loading.component";

class AchievementSlider extends React.Component {
  state = {
    loading: true
  };
  componentDidMount() {
    this.props.getAchievements();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({ loading: nextProps.achievements.loading });
    }
  }
  render() {
    const { loading } = this.state;
    const { achievements } = this.props.achievements;
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      adaptiveHeight: 1,
      focusOnSelect: true,
      centerPadding: 30,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div>
        {loading ? (
          <div className="loading">
            <Loading loading={loading} type={"ball"} />
          </div>
        ) : (
          <Slider {...settings}>
            {achievements.map(({ _id, ...others }) => (
              <div className="item" key={_id}>
                <Achievement key={_id} id={_id} {...others} />
              </div>
            ))}
          </Slider>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  achievements: state.achievements
});
export default connect(
  mapStateToProps,
  { getAchievements }
)(AchievementSlider);
