import axios from "axios";
import { GET_PROGRAMS, GET_CURRICULUMS, SPINNER_LOADING } from "./types";
import Push from "push.js";

export const getPrograms = type => dispatch => {
  dispatch({
    type: SPINNER_LOADING
  });
  if (type) {
    axios
      .get(`/api/v1/programs?type=${type}`)
      .then(res => {
        dispatch({
          type: GET_PROGRAMS,
          payload: res.data
        });
      })
      .catch(err => {
        Push.create(err.toString());
      });
  } else {
    Push.create("An error occurs");
  }
};

export const getCurriculums = id => dispatch => {
  dispatch({
    type: SPINNER_LOADING
  });
  axios
    .get(`/api/v1/programs/${id}/curriculum`)
    .then(res => {
      dispatch({
        type: GET_CURRICULUMS,
        payload: res.data
      });
    })
    .catch(err => {
      Push.create(err.toString());
    });
};
