import React, { Fragment } from "react";
import "./faculty_details.styles.scss";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { getTeacherDetails } from "../../../../actions/teachers";
import Loading from "../../../../components/loading/loading.component";

class FacultyDetail extends React.Component {
  state = {
    loading: false,
    displayEducation: false,
    displayResearch: false,
    displayPublication: false,
    displayTeach: false,
    displayAboutMe: true,
    displayProjectAndScholarship: false
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id !== "undefined") {
      this.props.getTeacherDetails(id);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({
        loading: nextProps.teachers.loading
      });
    }
  }

  render() {
    const {
      loading,
      displayResearch,
      displayAboutMe,
      displayProjectAndScholarship,
      displayTeach,
      displayEducation,
      displayPublication
    } = this.state;
    const {
      avatar,
      full_name,
      phone,
      email,
      designation,
      qualification,
      status,
      researches,
      biography,
      project_scholarships,
      publications,
      teaches,
      research_profiles
    } = this.props.teachers.teacher_details;

    let content;
    if (displayResearch) {
      content = (
        <Fragment>
          <div className="mlr-16">{ReactHtmlParser(researches.content)}</div>
        </Fragment>
      );
    }
    if (displayProjectAndScholarship) {
      content = (
        <Fragment>
          <div className="mlr-16">
            {ReactHtmlParser(project_scholarships.content)}
          </div>
        </Fragment>
      );
    }
    if (displayPublication) {
      content = (
        <Fragment>
          {publications.map(({ content }) => (
            <div className="mlr-16">{ReactHtmlParser(content)}</div>
          ))}
        </Fragment>
      );
    }
    if (displayTeach) {
      content = (
        <Fragment>
          <div className="mlr-16">{ReactHtmlParser(teaches.content)}</div>
        </Fragment>
      );
    }
    if (displayEducation) {
      content = <div className="mlr-16">{ReactHtmlParser(qualification)}</div>;
    }
    if (!this.props.isLoaded || !this.props.teachers.teacher_details)
      return (
        <Fragment>
          <div className="loading">
            <Loading loading={loading} type={"ball"} />
          </div>
        </Fragment>
      );
    return (
      <Fragment>
        {loading ? (
          <Fragment>
            <div className="loading">
              <Loading loading={loading} type={"ball"} />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="container emp-profile">
              <div className="columns">
                <div className="column">
                  <div className="profile-img">
                    <img
                      className="final__image"
                      src={`${avatar}`}
                      alt="faculty_image"
                    />
                  </div>
                </div>
                <div className="column is-two-thirds">
                  <div className="profile-head">
                    <h1 className="title">{full_name}</h1>
                    <h2 className="subtitle">{designation}</h2>
                    <h4 className="weight-font">
                      Status:{" "}
                      <span className="span-person-details">On {status}</span>
                    </h4>
                    <h4 className="weight-font">
                      Phone:{" "}
                      <span className="span-person-details">{phone}</span>
                    </h4>
                    <h4 className="weight-font">
                      Email:{" "}
                      <span className="span-person-details">{email}</span>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <div className="profile-work">
                    <p>Important Links</p>
                    {research_profiles.map(({ org_name, profile_link }) => (
                      <a
                        href={profile_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {org_name}
                      </a>
                    ))}
                  </div>
                </div>

                <div className="column is-two-thirds">
                  <div className="main">
                    <div className="tabs is-centered">
                      <ul>
                        <li
                          className="space-evenly"
                          onClick={() => {
                            this.setState(() => ({
                              displayResearch: false,
                              displayAboutMe: true,
                              displayProjectAndScholarship: false,
                              displayPublication: false,
                              displayTeach: false,
                              displayEducation: false
                            }));
                          }}
                        >
                          Biography
                        </li>
                        <li
                          className="space-evenly"
                          onClick={() => {
                            this.setState(() => ({
                              displayResearch: false,
                              displayAboutMe: false,
                              displayEducation: true,
                              displayProjectAndScholarship: false,
                              displayPublication: false,
                              displayTeach: false
                            }));
                          }}
                        >
                          Education
                        </li>
                        <li
                          className="space-evenly"
                          onClick={() => {
                            this.setState(() => ({
                              displayProjectAndScholarship: true,
                              displayResearch: false,
                              displayAboutMe: false,
                              displayPublication: false,
                              displayTeach: false,
                              displayEducation: false
                            }));
                          }}
                        >
                          Projects and Scholarships
                        </li>
                        <li
                          className="space-evenly"
                          onClick={() => {
                            this.setState(() => ({
                              displayResearch: true,
                              displayAboutMe: false,
                              displayProjectAndScholarship: false,
                              displayPublication: false,
                              displayTeach: false,
                              displayEducation: false
                            }));
                          }}
                        >
                          Research
                        </li>
                        <li
                          className="space-evenly"
                          onClick={() => {
                            this.setState(() => ({
                              displayResearch: false,
                              displayPublication: true,
                              displayTeach: false,
                              displayAboutMe: false,
                              displayProjectAndScholarship: false,
                              displayEducation: false
                            }));
                          }}
                        >
                          Publications
                        </li>
                        <li
                          className="space-evenly"
                          onClick={() => {
                            this.setState(() => ({
                              displayResearch: false,
                              displayPublication: false,
                              displayTeach: true,
                              displayAboutMe: false,
                              displayProjectAndScholarship: false,
                              displayEducation: false
                            }));
                          }}
                        >
                          Teachings
                        </li>
                      </ul>
                    </div>

                    <div id="about_me" className="tab-content profile-tab">
                      <div className="about-me-text">
                        {displayAboutMe ? (
                          <Fragment>{ReactHtmlParser(biography)}</Fragment>
                        ) : (
                          <Fragment />
                        )}
                        {content}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  teachers: state.teachers,
  isLoaded: state.teachers.loaded
});
export default connect(
  mapStateToProps,
  { getTeacherDetails }
)(FacultyDetail);
