import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./app.styles.scss";
// import "./debug.css";
import NavBar from "../components/generic/NavBar/navbar.component";
import Footer from "../components/generic/Footer/footer.component";
import Home from "./../pages/home/home.page";
import { Research } from "../pages/research/research.page";
import { Contact } from "../pages/contact/contact.page";
import GraduateProgram from "../components/programs/program-list/graduate-program.component";
import ProgramDetail from "../components/programs/details/program-details.component";
import Login from "../components/accounts/login/login.component";
import EventDetail from "../pages/activities/event-details/event_details.page";
import Helps from "./../components/helps/helps.component";
import facultyCardList from "../components/about/faculty/card-list/faculty-card-list.component";
import NotFound from "../components/http-errors/not-found/not_found.component";
import GeneralNotice from "../pages/notices/general_notices.component";
import UndergradProgram from "../components/programs/program-list/undergrad-program.component";
import DiplomaProgram from "../components/programs/program-list/diploma-program.component";
import CertificateProgram from "../components/programs/program-list/certificate-program.component";
import FacultyDetail from "../pages/about/faculty/faculty_details/faculty_details.component";
import EventList from "./../pages/activities/event-lists/event-lists.component";
import AchievementDetails from "../pages/activities/achievement-details/achievement-details.component";
import Institution from "../pages/about/about_iict/institution/institution.component";
import History from "../pages/about/about_iict/history/history.component";
import AimsObjective from "../pages/about/about_iict/aims_and_obj/aims-and-objective.component";

class App extends Component {
  render() {
    return (
      <Router>
        <NavBar />

        {/* TODO: have to add breadcrumbs */}
        <div className="page-container">
          <section class="dummy-content">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about/institution" component={Institution} />
              <Route
                exact
                path="/about/aims_and_objectives"
                component={AimsObjective}
              />
              <Route exact path="/about/history" component={History} />
              <Route exact path="/people/faculty" component={facultyCardList} />
              <Route
                exact
                path="/people/officer_and_staffs"
                component={AimsObjective}
              />
              <Route exact path="/login" component={Login} />
              <Route exact path="/helps" component={Helps} />
              <Route
                exact
                path="/programs/undergraduate"
                component={UndergradProgram}
              />
              <Route exact path="/programs/diploma" component={DiplomaProgram} />
              <Route
                exact
                path="/programs/certificate"
                component={CertificateProgram}
              />
              <Route
                exact
                path="/programs/graduate"
                component={GraduateProgram}
              />
              <Route exact path="/research" component={Research} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/activities/events" component={EventList} />

              <Route
                exact
                path="/activities/events/:id/details"
                component={EventDetail}
              />

              <Route
                exact
                path="/activities/achievement/:id/details"
                component={AchievementDetails}
              />

              <Route
                exact
                path="/programs/:id/curriculum"
                component={ProgramDetail}
              />
              <Route
                exact
                path="/people/faculty/:id/details"
                component={FacultyDetail}
              />
              <Route exact path="/notices/general" component={GeneralNotice} />

              {/* page not found */}
              <Route path="*" component={NotFound} />
            </Switch>
          </section>
        </div>
        <Footer />
      </Router>
    );
  }
}

export default App;
