import React from "react";
import { withRouter } from "react-router-dom";
import "./Scss/achievementCard.styles.scss";
import TimeParser from "./../../../utils/time-parser";

const Achievement = ({ id, images, created_at, title, history }) => {
  return (
    <div className="card eq-hight">
      <div className="card-image">
        <figure className="image is-4by3">
          <img src={images[0]} alt="card_header" />
        </figure>
        <div
          className="card-content"
          style={{ background: "#FFFFFF", borderRadius: `${0}` }}
        >
          <div className="content">
            <div className="has-text-centered">
              <div>
                <h3 style={{ color: "#A51C30",fontSize:"16px"}}>
                  {TimeParser(created_at)}
                </h3>
                <div className="divider" style={{ border: "1px solid #A51C30" }}></div>
                <br />
                <h4 style={{ color: "#1E2126",fontSize:"16px"}}>{title}</h4>
                <br />
                <button
                  className="e-btn-achieve button center-button-achieve"
                  onClick={() =>
                    history.push(`/activities/achievement/${id}/details`)
                  }
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Achievement);
