import React, { Fragment } from 'react';
import { connect } from "react-redux";
import './general_notices.styles.css';
import NoticeCard from '../../components/notices/notice_card.component';
import Loading from "../../components/loading/loading.component";
import {
    getGeneralNotices,
    getUnderGraduateNotices,
    getPostGraduateNotices,
    getTrainingProgramNotices,
    getMiscNotices
} from "../../actions/notices";
class GeneralNotice extends React.Component {
    state = {
        loading: true,
        displayGeneralNotice: true,
        displayUndergradNotice: false,
        displayPostgradNotice: false,
        displayTainningProgramNotice: false,
        displayMiscNotice: false,

    };

    componentDidMount() {
        this.props.getGeneralNotices();
        this.props.getUnderGraduateNotices();
        this.props.getPostGraduateNotices();
        this.props.getTrainingProgramNotices();
        this.props.getMiscNotices();
        setTimeout(() => {
            this.setState({ loading: false });
        }, 3000);
    }

    render() {
        const {
            loading,
            displayGeneralNotice,
            displayUndergradNotice,
            displayPostgradNotice,
            displayTainningProgramNotice,
            displayMiscNotice,
        } = this.state;

        let content;

        if (displayUndergradNotice) {
            content = (
                <Fragment>
                    {this.props.under_graduate_notices.map(under_graduate_notice => (
                        <div>
                            <NoticeCard
                                key={under_graduate_notice._id}
                                title={under_graduate_notice.title}
                                content={under_graduate_notice.content}
                                date_created={under_graduate_notice.created_at}
                                file = {under_graduate_notice.file}
                            />
                            <br />
                        </div>
                    ))}
                </Fragment>
            );
        }

        if (displayPostgradNotice) {
            content = (
                <Fragment>
                    {this.props.post_graduate_notices.map(post_graduate_notice => (
                        <div>
                            <NoticeCard
                                key={post_graduate_notice._id}
                                title={post_graduate_notice.title}
                                content={post_graduate_notice.content}
                                date_created={post_graduate_notice.created_at}
                                file = {post_graduate_notice.file}
                            />
                            <br />
                        </div>
                    ))}
                </Fragment>
            );
        }



        if (displayTainningProgramNotice) {
            content = (
                <Fragment>

                    {this.props.trainning_programs.map(trainning_program => (
                        <div>
                            <NoticeCard
                                key={trainning_program._id}
                                title={trainning_program.title}
                                content={trainning_program.content}
                                date_created={trainning_program.created_at}
                                file = {trainning_program.file}
                            />
                            <br />
                        </div>
                    ))}

                </Fragment>
            );

        }

        if (displayMiscNotice) {
            content = (
                <Fragment>
                      {this.props.misc_notices.map(misc_notice => (
                        <div>
                            <NoticeCard
                                key={misc_notice._id}
                                title={misc_notice.title}
                                content={misc_notice.content}
                                date_created={misc_notice.created_at}
                                file = {misc_notice.file}
                            />
                            <br />
                        </div>
                    ))}
              </Fragment>
            );

        }

        return (
            <Fragment>
                {loading ? (
                    <div className="loading">
                        <Loading loading={loading} type={"ball"} />
                    </div>
                ) : (
                        <div className="container notice-container">
                            <div className="columns">
                                <div className="column is-one-fifth category-column">
                                    <div class="header" role="banner">
                                        <div class="nav-wrap">
                                            <nav class="main-nav" role="navigation">
                                                <ul class="unstyled list-hover-slide">
                                                    <li className={`tablinks ${displayGeneralNotice?'link_active':' '}`}><a className={`tablinks ${displayGeneralNotice?'link_active':' '}`} onClick={() => {
                                                        this.setState(() => ({
                                                            displayGeneralNotice: true,
                                                            displayUndergradNotice: false,
                                                            displayPostgradNotice: false,
                                                            displayTainningProgramNotice: false,
                                                            displayMiscNotice: false,

                                                        }));
                                                    }} >
                                                        General</a></li>
                                                    <li className={`tablinks ${displayUndergradNotice?'link_active':' '}`}><a className={`tablinks ${displayUndergradNotice?'link_active':' '}`} onClick={() => {
                                                        this.setState(() => ({
                                                            displayGeneralNotice: false,
                                                            displayUndergradNotice: true,
                                                            displayPostgradNotice: false,
                                                            displayTainningProgramNotice: false,
                                                            displayMiscNotice: false,

                                                        }));
                                                    }} >Under-Graduate Studies</a></li>
                                                    <li className={`tablinks ${displayPostgradNotice?'link_active':' '}`}><a className={`tablinks ${displayPostgradNotice?'link_active':' '}`} onClick={() => {
                                                        this.setState(() => ({
                                                            displayGeneralNotice: false,
                                                            displayUndergradNotice: false,
                                                            displayPostgradNotice: true,
                                                            displayTainningProgramNotice: false,
                                                            displayMiscNotice: false,

                                                        }));
                                                    }} >Post-Gradute Studies</a></li>
                                                    <li className={`tablinks ${displayTainningProgramNotice?'link_active':' '}`}><a className={`tablinks ${displayTainningProgramNotice?'link_active':' '}`} onClick={() => {
                                                        this.setState(() => ({
                                                            displayGeneralNotice: false,
                                                            displayUndergradNotice: false,
                                                            displayPostgradNotice: false,
                                                            displayTainningProgramNotice: true,
                                                            displayMiscNotice: false,

                                                        }));
                                                    }} >Training Programs</a></li>
                                                    <li className={`tablinks ${displayMiscNotice?'link_active':' '}`}><a className={`tablinks ${displayMiscNotice?'link_active':' '}`} onClick={() => {
                                                        this.setState(() => ({
                                                            displayGeneralNotice: false,
                                                            displayUndergradNotice: false,
                                                            displayPostgradNotice: false,
                                                            displayTainningProgramNotice: false,
                                                            displayMiscNotice: true,

                                                        }));
                                                    }} >Misc.</a></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>

                                </div>
                                <div className="column notice-col">
                                    <div id="about_me" className="tab-content">
                                        {displayGeneralNotice ? (<Fragment>

                                            {this.props.general_notices.map(general_notice => (
                                                <div>
                                                    <NoticeCard
                                                        key={general_notice._id}
                                                        title={general_notice.title}
                                                        content={general_notice.content}
                                                        date_created={general_notice.created_at}
                                                        file = {general_notice.file}
                                                    />
                                                    <br />
                                                </div>
                                            ))}
                                        </Fragment>)
                                            : (<Fragment />)
                                        }
                                        {content}

                                    </div>

                                </div>

                            </div>
                        </div>

                    )}
            </Fragment>



        );
    }
}

const mapStateToProps = state => ({
    general_notices: state.notices.general_notices,
    under_graduate_notices: state.notices.under_graduate_notices,
    post_graduate_notices: state.notices.post_graduate_notices,
    trainning_programs: state.notices.trainning_programs,
    misc_notices: state.notices.misc_notices

});

export default connect(
    mapStateToProps,
    {
        getGeneralNotices,
        getUnderGraduateNotices,
        getPostGraduateNotices,
        getTrainingProgramNotices,
        getMiscNotices
    }
)(GeneralNotice);