import React from "react";

class Research extends React.Component {
  render() {
    return (
      <div>
        <h1>This is Research Page</h1>
      </div>
    );
  }
}

export { Research };
