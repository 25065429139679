import {
  GET_PROGRAMS,
  GET_CURRICULUMS,
  SPINNER_LOADING
} from "../actions/types";

const initialState = {
  programs: [],
  curriculums: [],
  loading: false
};

const programsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROGRAMS:
      return {
        ...state,
        programs: action.payload,
        loading: false
      };
    case GET_CURRICULUMS:
      return {
        ...state,
        curriculums: action.payload,
        loading: false
      };
    case SPINNER_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default programsReducer;
