import React, { Component } from "react";
import { connect } from "react-redux";
import { getEvents } from "./../../../actions/events";
import Events from "./../../../components/home/Events/eventCard.component";

class EventList extends Component {
  componentDidMount() {
    this.props.getEvents();
  }
  render() {
    const { events } = this.props.events;
    return (
      <div className="container">
        <h1>
          Events
          <div
            className="divider"
            style={{ border: "2px solid #A51C30" }}
          ></div>
        </h1>
        <div style={{ marginBottom: "5vh" }}>
          <div
            className="columns is-multiline"
            style={{ marginLeft: "30px", marginRight: "30px" }}
          >
            {events.map(({ _id, ...others }) => (
              <div className="column is-one-quarter">
                <Events key={_id} id={_id} {...others} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  events: state.events
});
export default connect(
  mapStateToProps,
  { getEvents }
)(EventList);
