export const GET_TEACHERS = "GET_TEACHERS";
export const GET_FACULTY_DETAILS = "GET_FACULTY_DETAILS";
export const GET_PROGRAMS = "GET_PROGRAMS";
export const GET_CURRICULUMS = "GET_CURRICULUMS";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const GET_ERROR = "GET_ERROR";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SPINNER_LOADING = "SPINNER_LOADING";
export const GET_EVENT_DETAIL = "GET_EVENT_DETAIL";
export const GET_EVENTS = "GET_EVENTS";
export const GET_ACHIEVEMENTS = "GET_ACHIEVEMENTS";
export const GET_ACHIEVEMENT_DETAILS = "GET_ACHIEVEMENT_DETAILS";

export const GET_GENERAL_NOTICES = "GET_GENERAL_NOTICES";
export const GET_UNDER_GRADUATE_NOTICES = "GET_UNDERGRADUATE_NOTICES";
export const GET_POST_GRADUATE_NOTICES = "GET_POST_GRADUATE_NOTICES";
export const GET_TRAINNING_PROGRAM_NOTICES ="GET_TRAINNING_PROGRAM_NOTICES";
export const GET_MISC_NOTICES = "GET_MISC_NOTICES";