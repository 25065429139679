import { GET_ACHIEVEMENTS, SPINNER_LOADING } from "../actions/types";
import { GET_ACHIEVEMENT_DETAILS } from "./../actions/types";

const initialState = {
  achievements: [],
  achievement_details: [],
  loading: false
};

const achievementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SPINNER_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_ACHIEVEMENTS:
      return {
        ...state,
        achievements: action.payload,
        loading: false
      };
    case GET_ACHIEVEMENT_DETAILS:
      return {
        ...state,
        achievement_details: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
export default achievementsReducer;
