import {
  GET_TEACHERS,
  SPINNER_LOADING,
  GET_FACULTY_DETAILS
} from "./../actions/types";
const initialState = {
  teachers: [],
  teacher_details: {},
  loading: false,
  loaded: false
};

const teachersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SPINNER_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_TEACHERS:
      return {
        ...state,
        teachers: action.payload,
        loading: false
      };
    case GET_FACULTY_DETAILS:
      return {
        ...state,
        teacher_details: action.payload,
        loading: false,
        loaded: true
      };
    default:
      return state;
  }
};

export default teachersReducer;
