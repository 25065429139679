import React from "react";
import "./navbar.styles.css";
import { Navbar } from "rbx";
import "bulma/css/bulma.min.css";
import './style.scss';
import { layoutGenerator } from 'react-break';

const layout = layoutGenerator({
  mobile: 0,
  phablet: 550,
  tablet: 768,
  desktop: 1024,
});
const OnMobile = layout.is('mobile');
const OnAtMostTablet = layout.isAtMost('tablet');
const OnAtMostPhablet = layout.isAtMost('phablet');
const OnDesktop = layout.is('desktop');

const bgColor = {
  Dark: "#30393A",
  RedDark: "#FFFFFF"
};

class NavBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      scrollingLock: '',
      open: false
    };
    // example how to bind object in React ES6
    this.handleScroll = this.handleScroll.bind(this)
  }

  togglePanel(e) {
    this.setState({ open: !this.state.open })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {

    if (window.scrollY > 100) {
      console.log("should lock");
      this.setState({
        scrollingLock: 'is-fixed-top'
      });
    } else if (window.scrollY < 100) {
      console.log("not locked");
      this.setState({
        scrollingLock: ''
      });
    }

  }


  render() {
    return (
      <div>
        <OnDesktop>
          <Navbar
            className="navbar is-dark"
            role="navigation"
            aria-label="main navigation"
            style={{
              backgroundColor: bgColor.Dark
            }}
          >
            <Navbar.Menu>
              <div
                className="navbar-start"
                style={{
                  flexGrow: "1",
                  justifyContent: "center"
                }}
              >
                <Navbar.Item href="">Faculty</Navbar.Item>
                <Navbar.Item href="/staff">Staff</Navbar.Item>
                <Navbar.Item href="">Student</Navbar.Item>
                <Navbar.Item href="">Alumni</Navbar.Item>
                <Navbar.Item href="">Help</Navbar.Item>
              </div>
            </Navbar.Menu>
          </Navbar>

          <Navbar
            className={`navbar is-light down-navbar ${this.state.scrollingLock}`}
            role="navigation"
            aria-label="main navigation"
            style={{ backgroundColor: bgColor.RedDark }}
          >

            <img
              src="https://i.ibb.co/QdJGTLT/IICT-logo-Main.png"
              width="130"
              height="50"
              className="iict-logo"
              alt="iict logo"
            />
            <Navbar.Menu>
              <div
                className="navbar-start"
                style={{
                  flexGrow: "1",
                  justifyContent: "center"
                }}
              >
                <Navbar.Item href="/">Home</Navbar.Item>
                <Navbar.Item dropdown hoverable>
                  <Navbar.Link className="isDisabled" arrowless>
                    About
                </Navbar.Link>
                  <Navbar.Dropdown className="nav-dropdown">
                    <Navbar.Item
                      href="/about/institution/"
                      className="nav-hover-link"
                    >
                      Institution
                  </Navbar.Item>
                    <Navbar.Divider className="nav-hr" />
                    <Navbar.Item
                      href="/about/aims_and_objectives"
                      className="nav-hover-link"
                    >
                      Aims &amp; Objectives
                  </Navbar.Item>
                    <Navbar.Divider className="nav-hr" />
                    <Navbar.Item href="/about/history" className="nav-hover-link">
                      History
                  </Navbar.Item>
                  </Navbar.Dropdown>
                </Navbar.Item>

                <Navbar.Item dropdown hoverable>
                  <Navbar.Link className="isDisabled" arrowless>
                    People
                </Navbar.Link>
                  <Navbar.Dropdown className="nav-dropdown">
                    <Navbar.Item
                      className="nav-hover-link"
                      href="/people/faculty"
                    >
                      Faculty
                  </Navbar.Item>
                    <Navbar.Divider className="nav-hr" />
                    <Navbar.Item
                      href="/people/officer_and_staffs"
                      className="nav-hover-link"
                    >
                      Officers &amp; Staffs
                  </Navbar.Item>
                  </Navbar.Dropdown>
                </Navbar.Item>

                <Navbar.Item dropdown hoverable>
                  <Navbar.Link className="isDisabled" arrowless>
                    Programs
                </Navbar.Link>
                  <Navbar.Dropdown className="nav-dropdown">
                    <Navbar.Item
                      href="/programs/graduate"
                      className="nav-hover-link"
                    >
                      Graduate Programs
                  </Navbar.Item>
                    <Navbar.Divider className="nav-hr" />
                    <Navbar.Item
                      href="/programs/undergraduate"
                      className="nav-hover-link"
                    >
                      Undergraduate Programs
                  </Navbar.Item>
                    <Navbar.Divider className="nav-hr" />
                    <Navbar.Item
                      href="/programs/diploma"
                      className="nav-hover-link"
                    >
                      Diploma Courses
                  </Navbar.Item>
                    <Navbar.Divider className="nav-hr" />
                    <Navbar.Item
                      href="/programs/certificate"
                      className="nav-hover-link"
                    >
                      Certificate Courses
                  </Navbar.Item>
                  </Navbar.Dropdown>
                </Navbar.Item>
                <Navbar.Item dropdown hoverable>
                  <Navbar.Link className="isDisabled" arrowless>
                    Activities
                </Navbar.Link>
                  <Navbar.Dropdown className="nav-dropdown">
                    <Navbar.Item
                      href="/activities/events"
                      className="nav-hover-link"
                    >
                      Events &amp; News
                  </Navbar.Item>
                    <Navbar.Divider className="nav-hr" />
                    <Navbar.Item
                      href="/activities/upcoming_events"
                      className="nav-hover-link"
                    >
                      Upcoming Events
                  </Navbar.Item>
                  </Navbar.Dropdown>
                </Navbar.Item>

                <Navbar.Item href="/notices/general">Notices</Navbar.Item>
                <Navbar.Item href="/research">Researches</Navbar.Item>
                <Navbar.Item href="/contact">Contacts</Navbar.Item>
              </div>
            </Navbar.Menu>
            <div>
              <img
                src="https://www.sust.edu/images/logo.png"
                width="90"
                height="18"
                className="sust-logo"
                id="sust-logo-id"
                alt="sust logo"
              />
            </div>
          </Navbar>
        </OnDesktop>

        <OnAtMostTablet>

          <Navbar className={`fixed_nav ${this.state.scrollingLock}`}>
            <img
              src="https://i.ibb.co/QdJGTLT/IICT-logo-Main.png"
              width="130"
              height="50"
              className="iict-logo-one"
              alt="iict logo"
            />
            <input id="toggle" type="checkbox" />


            <label class="toggle-container" for="toggle">

              <span class="button button-toggle"></span>
            </label>


            <nav class="nav">
              <a class="nav-item" href="/" >Home</a>
              <div onClick={(e) => this.togglePanel(e)} className="nav-item">
                About</div>
              {this.state.open ? (
                <div className="sub-item">
                  <a className="nav-item" href="/about/institution/">
                    Institution
                </a>
                  <a className="nav-item" href="/about/aims_and_objectives">
                    Aims & Objectives
              </a>
                  <a className="nav-item" href="/about/history">
                    History
              </a>

                </div>

              ) : null}

              <div onClick={(e) => this.togglePanel(e)} className="nav-item">
                People</div>
              {this.state.open ? (
                <div className="sub-item">
                  <a className="nav-item" href="/people/faculty">
                    Faculty
                </a>
                  <a className="nav-item" href="/people/officer_and_staffs">
                    Officers & Staffs
                </a>

                </div>

              ) : null}

              <div onClick={(e) => this.togglePanel(e)} className="nav-item">
                Programs</div>
              {this.state.open ? (
                <div className="sub-item" >
                  <a className="nav-item" href="/programs/graduate">
                    Graduate Programs
                </a>
                  <a className="nav-item" href="/programs/undergraduate">
                    Undergraduate Programs
                </a>
                  <a className="nav-item" href="/programs/diploma">
                    Diploma Courses
                </a>
                  <a className="nav-item" href="/programs/certificate">
                    Certificate Courses
                </a>

                </div>
              ) : null}

              <div onClick={(e) => this.togglePanel(e)} className="nav-item">
                Activities</div>
              {this.state.open ? (
                <div className="sub-item">
                  <a className="nav-item" href="/activities/events">
                    News & Events
                </a>
                  <a className="nav-item" href="/activities/upcoming_events">
                    Upcoming Events
                  </a>

                </div>

              ) : null}


              <a class="nav-item" href="/notices/general" >Notices</a>
              <a class="nav-item" href="/research" >Researches</a>
              <a class="nav-item" href="/contact" >Contacts</a>

            </nav>
          </Navbar>
        </OnAtMostTablet>
      </div>
    );
  }
}

export default NavBar;
