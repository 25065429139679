import {
  GET_EVENTS,
  GET_EVENT_DETAIL,
  SPINNER_LOADING
} from "../actions/types";

const initialState = {
  events: [],
  event_details: [],
  loading: false,
  loaded: false
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SPINNER_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_EVENTS:
      return {
        ...state,
        events: action.payload,
        loading: false,
        loaded: true
      };
    case GET_EVENT_DETAIL:
      return {
        ...state,
        event_details: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
export default eventsReducer;
