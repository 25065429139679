import React from "react";
import { Link } from "react-router-dom";
import "./footer.styles.css";

const bgColor = {
  Dark: "#30393A"
};

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="footer" style={{ backgroundColor: bgColor.Dark }}>
        <div className="content">
          <div className="columns">
            <div className="column">
              <h1 id="header-footer">WEBSITE</h1>
              <div className="footer-links">
                <Link className="links">Sitemap</Link>
                <br />
                <Link className="links">Privacy and Cookies</Link>
                <br />
                <Link className="links">Contact Us</Link>
              </div>
            </div>
            <div className="column">
              <h1 id="header-footer">CONTACT & SOCIAL MEDIA</h1>
              <address className="address">
                IICT , SUST <br />
                Sylhet, Bangladesh
                <br />
                Phone: +88017000000
                <br />
                Website : www.iict.sust.edu
                <br />
                Email: iict@sust.edu
              </address>
            </div>
            <div className="column">
              <h1 id="header-footer">VISION</h1>
              <p className="vision-para">
                To create future leaders and entrepreneurs who can accelerate progress in information and
                technology by exploring new dimensions to represent Bangladesh in global platforms.
              </p>
            </div>
          </div>
        </div>
        <hr className="footer-hr" />
        <div className="copyright">
          <p className="copyright-text">
            Copyright &copy; {new Date().getFullYear()} All Rights Reserved by
            <Link to="/"> IICT</Link>
          </p>
        </div>
      </footer>
    );
  }
}
