import React from "react";
import "../history/history.styles.scss";

class History extends React.Component {
  render() {
    return (
      <div className="container">
        <h1>
          History
          <div
            className="divider"
            style={{ border: "2px solid #A51C30" }}
          ></div>
        </h1>
        <p>
          Lorem ipsum dolor sit amet,consectetur adipiscing elit. Praesent at
          velit sollicitudin, eleifend orci eu, gravida turpis.Aliquam erat
          volutpat. Pellentesque congue mi mauris, vitae porttitor quam
          scelerisque in. Etiam nec dui nec purus feugiat dictum at at nibh.
          Aenean rutrum eget purus ac semper. Nullam ut turpis facilisis,
          pellentesque augue a, efficitur est. Quisque finibus eros magna, ut
          tempus mi fermentum sed. Nunc fringilla gravida arcu, et convallis
          nisi vehicula ac. Mauris placerat sollicitudin arcu sit amet vehicula.
          Nunc quis lectus placerat, auctor est non, faucibus eros. Donec
          fringilla, nunc at suscipit convallis, nibh ipsum pharetra dolor,
          ultricies mollis odio velit scelerisque neque. Aliquam placerat tortor
          eget sapien molestie, id condimentum velit tempor. Vestibulum ultrices
          ante ut lorem molestie, et semper erat sollicitudin. Sed consequat
          ipsum ut posuere dignissim. In in metus massa. Etiam porttitor est
          mauris, eu placerat velit tincidunt et.
        </p>
        <br />
        <section class="hero is-bold has-text-centered">
          <div class="hero-body">
            <div class="container">
              <h1
                class="title is-uppercase"
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#A51C30"
                }}
              >
                Roll of Honour
              </h1>
              <p class="is-size-4">
                Institution of Information & Communication Technology(2015-till
                date)
              </p>
            </div>
          </div>
        </section>
        <div className="level">
          <div className="level-item has-text-centered">
            <table className="table is-responsive is-striped is-bordered">
              <thead>
                <tr>
                  <td>Director Name</td>
                  <td>From</td>
                  <td>To</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Dr Muhammad Zafar Iqbal</td>
                  <td>01-January-2000</td>
                  <td>01-May-1236</td>
                </tr>
                <tr>
                  <td>Dr Shahidur Rahman</td>
                  <td>01-January-2000</td>
                  <td>01-May-1236</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <br />
      </div>
    );
  }
}
export default History;
