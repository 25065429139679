import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getPrograms } from "../../../actions/programs";
import "./programs.styles.scss";
import { Link } from "react-router-dom";
import Loading from "../../loading/loading.component";

class CertificateProgram extends Component {
  state = {
    loading: false
  };
  componentDidMount() {
    this.props.getPrograms("Certificate");
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({
        loading: nextProps.programs.loading
      });
    }
  }

  render() {
    const { loading } = this.state;
    const { programs } = this.props.programs;
    return (
      <div className="container program-body">
        {loading ? (
          <Fragment>
            <div className="loading">
              <Loading loading={loading} type={"ball"} />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <ul className="mt-10">
              <h2 className="title left-border">
                <span className="ml-10">Certified Programs</span>
              </h2>
              {programs.map(program => (
                <li key={program._id} className="mb-10">
                  <div className="box box-size">
                    <div className="justify-box-content">
                      <p>{program.title}</p>
                      {program.total_credit !== 0 && (
                        <p>
                          Total Credit:
                          <span className="ml-3">{program.total_credit}</span>
                        </p>
                      )}
                      <p>
                        Course Length:
                        <span className="ml-3">
                          {program.course_length} {program.course_length_type}
                        </span>
                      </p>
                      {program.total_semester !== 0 && (
                        <p>
                          Total Semester:
                          <span className="ml-3">{program.total_semester}</span>
                        </p>
                      )}
                    </div>
                    <Link
                      className="button primary align-right fit-content"
                      to={`/programs/${program._id}/curriculum`}
                    >
                      Curriculum
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
          </Fragment>
        )}
      </div>
    );
  }
}
const mapPropsToState = state => ({
  programs: state.programs
});
export default connect(
  mapPropsToState,
  { getPrograms }
)(CertificateProgram);
