import axios from "axios";
import jwt_decode from "jwt-decode";
import { SET_CURRENT_USER, GET_ERROR } from "./types";
import setAuthToken from "../utils/setAuthToken";

export const userLogin = (email, password) => dispatch => {
  axios
    .post("/api/auth/token/", {
      email: email,
      password: password
    })
    .then(res => {
      const { access } = res.data;
      // save token
      localStorage.setItem("accessToken", access);
      // set token to auth header
      //   setAuthToken(access);
      setAuthToken(access);
      const decode = jwt_decode(access);
      dispatch(setCurrentUser(decode));
    })
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data
      })
    );
};
export const setCurrentUser = decode => {
  return {
    type: SET_CURRENT_USER,
    payload: decode
  };
};
//Logout user
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("accessToken");
  // Remove header from axios request
  setAuthToken(false);
  dispatch(setCurrentUser({}));
};
