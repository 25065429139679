import React, { Fragment } from "react";
import Slider from "react-slick";
import { connect } from "react-redux";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Scss/eventsCardSlider.styles.scss";
import Events from "./eventCard.component";
import { getEvents } from "./../../../actions/events";
import Loading from "../../loading/loading.component";

class EventCardSlider extends React.Component {
  state = {
    loading: false
  };
  componentDidMount() {
    this.props.getEvents();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({
        loading: nextProps.events.loading
      });
    }
  }
  render() {
    const { loading } = this.state;
    const { events } = this.props.events;
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      adaptiveHeight: 1,
      focusOnSelect: true,
      centerPadding: 30,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    if (!this.props.isLoaded || !this.props.events)
      return (
        <Fragment>
          <div className="loading">
            <Loading loading={loading} type={"ball"} />
          </div>
        </Fragment>
      );
    return (
      <div>
        {loading ? (
          <div className="loading">
            <Loading loading={loading} type={"ball"} />
          </div>
        ) : (
          <Slider {...settings}>
            {events.map(({ _id, ...others }) => (
              <div className="item" key={_id}>
                <Events key={_id} id={_id} {...others} />
              </div>
            ))}
          </Slider>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  events: state.events,
  isLoaded: state.events.loaded
});
export default connect(
  mapStateToProps,
  { getEvents }
)(EventCardSlider);
