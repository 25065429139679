import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { getCurriculums } from "../../../actions/programs";
import PropTypes from "prop-types";
import "./program-details.styles.scss";
import Loading from "./../../loading/loading.component";
export class ProgramDetail extends Component {
  static propTypes = {
    getCurriculums: PropTypes.func.isRequired
  };
  state = {
    loading: true
  };
  componentDidMount() {
    this.props.getCurriculums(this.props.match.params.id);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  }
  render() {
    const { loading } = this.state;
    return (
      <Fragment>
        {loading ? (
          <div className="loading">
            <Loading loading={loading} type={"ball"} />
          </div>
        ) : (
          <Fragment>
            {this.props.curriculums.map(curriculum => (
              <div key={curriculum._id} className="curriculum-detail">
                <div className="content mt-10">
                  <h2 className="title left-border">
                    <span className="ml-10">
                      Curriculum for the batch of {curriculum.batch}
                    </span>
                  </h2>
                  {ReactHtmlParser(curriculum.content)}
                </div>
              </div>
            ))}
          </Fragment>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  curriculums: state.programs.curriculums
});
export default connect(
  mapStateToProps,
  { getCurriculums }
)(ProgramDetail);
