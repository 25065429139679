import React, { Fragment } from "react";
import "./not_found.styles.css";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <Fragment>
      <div className="central-body bg-purple">
        <img
          className="image-404"
          src="http://salehriaz.com/404Page/img/404.svg"
          width="300px"
          alt=""
        />
        <Link to="/" className="btn-go-home">
          GO BACK HOME
        </Link>
      </div>
      <div className="objects">
        <img
          className="object_rocket"
          src="http://salehriaz.com/404Page/img/rocket.svg"
          width="40px"
          alt=""
        />
        <div className="earth-moon">
          <img
            className="object_earth"
            src="http://salehriaz.com/404Page/img/earth.svg"
            width="100px"
            alt=""
          />
          <img
            className="object_moon"
            src="http://salehriaz.com/404Page/img/moon.svg"
            width="80px"
            alt=""
          />
        </div>
        <div className="box_astronaut">
          <img
            className="object_astronaut"
            src="http://salehriaz.com/404Page/img/astronaut.svg"
            width="140px"
            alt=""
          />
        </div>
      </div>
      <div className="glowing_stars">
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
      </div>
    </Fragment>
  );
};
export default NotFound;
