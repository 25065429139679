import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { userLogin } from "./../../../actions/auth";
import "./login.styles.scss";

class Login extends Component {
  state = {
    email: "",
    password: ""
  };
  onChange = e => this.setState({ [e.target.name]: e.target.value });
  onSubmit = e => {
    e.preventDefault();
    this.props.userLogin(this.state.email, this.state.password);
  };
  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }
    return (
      <div className="hero">
        <div className="hero-body">
          <h1 className="title has-text-centered is-size-2">Login</h1>
          <div className="columns is-centered">
            <div className="column is-half">
              <div className="notification is-dark-red">
                <figure className="image container is-64x64">
                  <img
                    src="https://i.postimg.cc/KvTSV6JZ/iict-logo.png"
                    alt="button"
                  />
                </figure>
                <form onSubmit={this.onSubmit}>
                  <div className="field">
                    <label className="label is-white">Email</label>
                    <p className="control has-icons-left has-icons-right">
                      <input
                        className="input"
                        type="email"
                        placeholder="Email"
                        name="email"
                        onChange={this.onChange}
                        value={this.state.email}
                        required
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-envelope"></i>
                      </span>
                    </p>
                  </div>
                  <div className="field">
                    <label className="label is-white">Password:</label>
                    <p className="control has-icons-left">
                      <input
                        className="input"
                        type="password"
                        placeholder="Password"
                        name="password"
                        onChange={this.onChange}
                        required
                        value={this.state.password}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-lock"></i>
                      </span>
                    </p>
                  </div>
                  <button className="btn button is-rounded is-outlined is-medium">
                    Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});
export default connect(
  mapStateToProps,
  { userLogin }
)(Login);
