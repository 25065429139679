import React from 'react';
import './notice_card.styles.css';

class NoticeCard extends React.Component {
    render() {
        return (
            <div class="toast__container">
                <div class="toast__cell">
                    <div class="container toast toast--green">
                        <div class="toast__content">
                            <div className="columns">
                                <div className="column">
                                    <p class="toast__type">{this.props.title}</p>
                                    <p class="toast__message">{this.props.content}</p>
                                </div>
                                <div className="column is-one-quarter">
                                    <div className="date-time">
                                        <span><i class="fas fa-calendar"></i> {this.props.date_created}</span>

                                    </div>
                                <div class="notice-download-button" id="notice-download-button-Id">
                                        <div id="underline"></div>
                                        <a className="notice_download_link" href={this.props.file}>Download</a>
                                    </div>
                                </div>

                            </div>


                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default NoticeCard;