import axios from "axios";
import {
  GET_ACHIEVEMENTS,
  GET_ERROR,
  SPINNER_LOADING,
  GET_ACHIEVEMENT_DETAILS
} from "./types";

export const getAchievements = () => dispatch => {
  dispatch({
    type: SPINNER_LOADING
  });
  axios
    .get("/api/v1/events_achievement/achievements/")
    .then(res =>
      dispatch({
        type: GET_ACHIEVEMENTS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response
      })
    );
};

export const getAchievementDetails = id => dispatch => {
  dispatch({
    type: SPINNER_LOADING
  });
  axios
    .get(`/api/v1/events_achievement/achievements/${id}`)
    .then(res => {
      dispatch({
        type: GET_ACHIEVEMENT_DETAILS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERROR,
        payload: err.response.status
      });
    });
};
