import React from "react";
import "../institution/institution.styles.scss";
import Slider from "react-slick";

class Institution extends React.Component {
  render() {
    const settings = {
      dots: false,
      arrows: false,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000
    };
    return (
      <div className="container">
        <h1>
          Institution
          <div
            className="divider"
            style={{ border: "2px solid #A51C30" }}
          ></div>
        </h1>
        <div className="text-container-left">
          <p>
            Lorem ipsum dolor sit amet,consectetur adipiscing elit. Praesent at
            velit sollicitudin, eleifend orci eu, gravida turpis.Aliquam erat
            volutpat. Pellentesque congue mi mauris, vitae porttitor quam
            scelerisque in. Etiam nec dui nec purus feugiat dictum at at nibh.
            Aenean rutrum eget purus ac semper. Nullam ut turpis facilisis,
            pellentesque augue a, efficitur est. Quisque finibus eros magna, ut
            tempus mi fermentum sed. Nunc fringilla gravida arcu, et convallis
            nisi vehicula ac. Mauris placerat sollicitudin arcu sit amet
            vehicula. Nunc quis lectus placerat, auctor est non, faucibus eros.
            Donec fringilla, nunc at suscipit convallis, nibh ipsum pharetra
            dolor, ultricies mollis odio velit scelerisque neque. Aliquam
            placerat tortor eget sapien molestie, id condimentum velit tempor.
            Vestibulum ultrices ante ut lorem molestie, et semper erat
            sollicitudin. Sed consequat ipsum ut posuere dignissim. In in metus
            massa. Etiam porttitor est mauris, eu placerat velit tincidunt et.
          </p>
        </div>
        <div className="slide-container-right">
          <Slider {...settings}>
            <div>
              <img
                src={
                  "https://i.ibb.co/QbFXzv5/12184314-10206841291583756-8160153185914906072-o.jpg"
                }
                alt="institute_image"
              />
            </div>
            <div>
              <img
                src={
                  "https://i.ibb.co/gdBWwTk/13254719-10208190020901146-8499449624705079435-o.jpg"
                }
                alt="institute_image"
              />
            </div>
            <div>
              <img
                src={
                  "https://i.ibb.co/hR6Q2Xh/10984509-10205086958846534-110806623408548854-o.jpg"
                }
                alt="institute_image"
              />
            </div>
            <div>
              <img
                src={"https://i.ibb.co/P1zGVPK/iict-class.png"}
                alt="institute_image"
              />
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}
export default Institution;
