import {
  GET_GENERAL_NOTICES,
  GET_UNDER_GRADUATE_NOTICES,
  GET_POST_GRADUATE_NOTICES,
  GET_TRAINNING_PROGRAM_NOTICES,
  GET_MISC_NOTICES
} from "./../actions/types";

const initialState = {
  general_notices: [],
  under_graduate_notices: [],
  post_graduate_notices: [],
  trainning_programs: [],
  misc_notices: [],
};

const noticesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GENERAL_NOTICES:
      return {
        ...state,
        general_notices: action.payload
      };
    case GET_UNDER_GRADUATE_NOTICES:
      return {
        ...state,
        under_graduate_notices: action.payload
      };
    case GET_POST_GRADUATE_NOTICES:
      return {
        ...state,
        post_graduate_notices: action.payload
      };
    case GET_TRAINNING_PROGRAM_NOTICES:
      return {
        ...state,
        trainning_programs: action.payload
      };
    case GET_MISC_NOTICES:
      return {
        ...state,
        misc_notices: action.payload

      };
    default:
      return state;
  }
};

export default noticesReducer;
