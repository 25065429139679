import { combineReducers } from "redux";
import teachers from "./teachers";
import programs from "./programs";
import auth from "./auth";
import errors from "./errors";
import notices from "./notices";
import events from "./events";
import achievements from "./achievements";

export default combineReducers({
  teachers,
  programs,
  auth,
  notices,
  errors,
  events,
  achievements
});
