import React, { Fragment } from "react";
import { connect } from "react-redux";
import FacultyCard from "../card/faculty-card.component";
import "./faculty-card-list.styles.scss";
import Loading from "../../../loading/loading.component";
import { getTeachers } from "../../../../actions/teachers";
class FacultyCardList extends React.Component {
  state = {
    loading: false,
    error_status: null
  };
  componentDidMount() {
    this.props.getTeachers();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({
        loading: nextProps.teachers.loading,
        error_status: nextProps.errors
      });
    }
  }
  render() {
    const { loading } = this.state;
    const { teachers } = this.props.teachers;
    return (
      <Fragment>
        {loading ? (
          <div className="loading">
            <Loading loading={loading} type={"ball"} />
          </div>
        ) : (
          <Fragment>
            <div className="card-list container">
              {teachers.map(teacher => (
                <FacultyCard
                  key={teacher._id}
                  id={teacher._id}
                  name={teacher.full_name}
                  designation={teacher.designation}
                  avatar={teacher.avatar}
                />
              ))}
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  teachers: state.teachers,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { getTeachers }
)(FacultyCardList);
