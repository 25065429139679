import axios from "axios";
import { 
  GET_GENERAL_NOTICES,
  GET_UNDER_GRADUATE_NOTICES,
  GET_POST_GRADUATE_NOTICES,
  GET_TRAINNING_PROGRAM_NOTICES,
  GET_MISC_NOTICES
} from "./types";

export const getGeneralNotices = () => dispatch => {
  axios
    .get(`/api/v1/notices/?category=general`)
    .then(res => {
      dispatch({
        type: GET_GENERAL_NOTICES,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

export const getUnderGraduateNotices = () => dispatch => {
  axios
    .get(`/api/v1/notices/?category=undergraduate`)
    .then(res => {
      dispatch({
        type: GET_UNDER_GRADUATE_NOTICES,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

export const getPostGraduateNotices = () => dispatch => {
  axios
    .get(`/api/v1/notices/?category=graduate`)
    .then(res => {
      dispatch({
        type: GET_POST_GRADUATE_NOTICES,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

export const getTrainingProgramNotices = () => dispatch => {
  axios
    .get(`/api/v1/notices/?category=training`)
    .then(res => {
      dispatch({
        type: GET_TRAINNING_PROGRAM_NOTICES,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

export const getMiscNotices = () => dispatch => {
  axios
    .get(`/api/v1/notices/?category=misc`)
    .then(res => {
      dispatch({
        type: GET_MISC_NOTICES,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};