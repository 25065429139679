import axios from "axios";
import {
  GET_TEACHERS,
  GET_ERROR,
  SPINNER_LOADING,
  GET_FACULTY_DETAILS
} from "./types";

export const getTeachers = () => dispatch => {
  dispatch({
    type: SPINNER_LOADING
  });
  axios
    .get("/api/v1/institute/teachers")
    .then(res => {
      dispatch({
        type: GET_TEACHERS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERROR,
        payload: err.response.status
      });
    });
};
export const getTeacherDetails = id => dispatch => {
  dispatch({
    type: SPINNER_LOADING
  });
  axios
    .get(`/api/v1/institute/teachers/${id}/`)
    .then(res => {
      dispatch({ type: GET_FACULTY_DETAILS, payload: res.data });
    })
    .catch(err => {
      dispatch({
        type: GET_ERROR,
        payload: err.response.status
      });
    });
};
